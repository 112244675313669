import React, { useState, useEffect, useRef } from "react";
import { MdKeyboardArrowRight, MdCheckCircle } from "react-icons/md";
import { CiDiscount1, CiShop, CiMobile3 } from "react-icons/ci";
import { PiBookLight } from "react-icons/pi";
import axiosConfig from "../Service/axiosConfig";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addProduct,
  selectCartProducts,
  selectCartTotal,
  removeProduct,
  clearCart,
  fetchProducts,
} from "../redux/Slices/cartSlice";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  LoadScript,
} from "@react-google-maps/api";
import Select from "react-select";
import { PopupModal } from "../Components";
import Modal from "react-bootstrap/Modal";
import { RiMotorbikeFill } from "react-icons/ri";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { MdOutlineShoppingBag } from "react-icons/md";
import { BsFileTextFill } from "react-icons/bs";
const librariesArray = ["places"];

const Checkout = () => {
  const [deliveryOptions, setDeliveryOptions] = useState([
    "DeliveryBy",
    "Express",
    "Scheduled",
  ]);
  const [delOptions, setOptions] = useState([""]);
  const total = useSelector(selectCartTotal);
  const [selectedOption, setSelectedOption] = useState("");
  const [popupType, setPopupType] = useState("");
  const [message, setMessage] = useState(null);
  const [Error, SetError] = useState("");
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [waiverData, setWaiverData] = useState({});
  const [deliveryData, setDeliveryData] = useState({});
  const [packingData, setPackingData] = useState([]);
  let Navigate = useNavigate();
  const [Address, setAddress] = useState({});
  const [news, SetNews] = useState(null);
  const [Order, setOrder] = useState([]);
  const [charges, setCharges] = useState({});
  const [mode, setMode] = useState(null);
  const [CodAmout, setCodAmount] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [deliveryChargesWaived, setDeliveryChargesWaived] = useState(0);
  const [packingCharges, setPackingCharges] = useState(0);
  const [packingChargesWaived, setPackingChargesWaived] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 17.38714,
    lng: 78.491684,
  });
  const [slots, setSlots] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [slotOptions, setSlotOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem("selected_date") || null
  );
  const [selectedSlot, setSelectedSlot] = useState(
    localStorage.getItem("selected_slot") || null
  );
  const [defaultSlot, setDefaultSlot] = useState("");
  const [deliveryType, setDeliveryType] = useState(
    localStorage.getItem("delivery_type") || "Express"
  );
  const products = useSelector(selectCartProducts);
  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [ErrorMsg, setErrorMsg] = useState(null);
  const [scheduleStr, setScheduleStr] = useState(null);
  const [expressStr, setExpressStr] = useState(null);
  const [speed, setSpeed] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [Express, setExpress] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [couponApplied, setCouponApplied] = useState([]);
  const [discountTotals, setDiscountTotals] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState(null);
  const [applyCouponError, setApplyCouponError] = useState(null);
  const handleOptionChange = (i) => {
    setSelectedOption(deliveryOptions[i]);
    if (
      deliveryOptions[i] === "DeliveryBy" ||
      deliveryOptions[i] === "Scheduled"
    ) {
      if (!charges.schedule_waier_delivery_charges) {
        setDeliveryCharges(charges.schedule_delivery_charges);
      } else {
        setDeliveryCharges(0);
        setDeliveryChargesWaived(charges.schedule_delivery_charges);
      }
      if (!charges.schedule_waier_packing_charges) {
        setPackingCharges(charges.schedule_packing_charges);
      } else {
        setPackingCharges(0);
        setPackingChargesWaived(charges.schedule_packing_charges);
      }
      setDeliveryType("Schedule");
      if (deliveryOptions[i] === "Scheduled") {
        setScheduleStr(`by ${selectedSlot || defaultSlot} on ${selectedDate}`);
      } else {
        setScheduleStr(null);
      }
      setExpressStr(null);
    } else if (deliveryOptions[i] === "Express") {
      if (!charges.express_waier_delivery_charges) {
        setDeliveryCharges(charges.express_delivery_charges);
      } else {
        setDeliveryCharges(0);
        setDeliveryChargesWaived(charges.express_delivery_charges);
      }
      if (!charges.express_waier_packing_charges) {
        setPackingCharges(charges.express_packing_charges);
      } else {
        setPackingCharges(0);
        setPackingChargesWaived(charges.express_packing_charges);
      }
      setDeliveryType("Express");
      setExpressStr(
        `Delivery in ${localStorage.getItem(
          "express_delivery_time"
        )} minutes on ${new Date().toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })}`
      );
      setScheduleStr(null);
      setErrorMsg(null);
      SetError("");
    }
  };
  const HandleMiniPopUpClose = () => {
    setModalShow(false);
  };
  const handleShowModal = (title) => {
    setModalTitle(title);
    setModalShow(true);
  };
  function calculateDiscountTotals(couponApplied) {
    return couponApplied.reduce(
      (totals, item) => {
        if (item.discount_type === "BogoDiscount") {
          totals.bogo_discount += parseFloat(item.coupon_discount);
        } else if (item.discount_type === "DiscountCoupon") {
          totals.product_discount += parseFloat(item.coupon_discount);
        } else if (item.discount_type === "FreeOrDiscountedItemWithPurchase") {
          totals.free_item += parseFloat(item.coupon_discount);
        } else if (item.discount_type === "FreeDeliveryChargesCoupon") {
          console.log("discountTotals", totals, item);
          totals.free_delivery_charges += parseFloat(item.coupon_discount);
        }
        return totals;
      },
      {
        bogo_discount: 0,
        product_discount: 0,
        free_item: 0,
        free_delivery_charges: 0,
      }
    );
  }
  const removeFromCart = async (item) => {
    try {
      let product_to_update = Order.products
        .map((product) => {
          if (product.id === item.id) {
            product.min_qty -= 1;
          }
          return product;
        })
        .filter((product) => product.min_qty > 0);
      let response = await axiosConfig.put(
        `/accounts/orders/${localStorage.getItem("orderId")}/`,
        {
          ...Order,
          products: product_to_update,
          delivery_charges: deliveryCharges,
        }
      );
      dispatch(removeProduct({ productId: item.id, quantity: 1 }));
      setCouponApplied(response.data?.coupon_code);
      let totals = calculateDiscountTotals(response.data?.coupon_code);
      setDiscountTotals(totals);
      setApplyCouponError(null);
      setErrorMsg(null);
    } catch (error) {
      console.log("Error in removing from cart:", error);
      setErrorMsg(error.response.data.message);
      if (error.response.data.errors.length > 0) {
        setApplyCouponError(error.response.data.errors[0]);
        dispatch(removeProduct({ productId: item.id, quantity: 1 }));
        getOrder();
      }
    }
  };

  const addToCart = async (item) => {
    try {
      let updatedOrder = { ...Order };

      const productExists = updatedOrder.products.find(
        (product) => product.id === item.id
      );

      if (!productExists) {
        updatedOrder = {
          ...updatedOrder,
          products: [...updatedOrder.products, { ...item, min_qty: 1 }],
        };
        // setOrder(updatedOrder);
      } else {
        let product_to_update = updatedOrder.products.map((product) => {
          if (product.id === item.id) {
            return { ...product, min_qty: product.min_qty + 1 };
          }
          return product;
        });

        updatedOrder = {
          ...updatedOrder,
          products: product_to_update,
          delivery_charges: deliveryCharges,
        };
        // setOrder(updatedOrder);
      }

      let response = await axiosConfig.put(
        `/accounts/orders/${localStorage.getItem("orderId")}/`,
        {
          ...updatedOrder,
        }
      );

      dispatch(
        addProduct({
          product: {
            id: item.id,
            name: item.name,
            image: item.image.image,
            price: item.price,
            offer_price: item.offer_price,
            weight_in_kg: item.weight_in_kg,
            max_qty: item.max_qty,
            subcategory: item.subcategory,
            speed: item.category,
          },
          quantity: 1,
        })
      );

      setCouponApplied(response.data?.coupon_code);
      let totals = calculateDiscountTotals(response.data?.coupon_code);
      setDiscountTotals(totals);
      setApplyCouponError(null);
      setErrorMsg(null);
    } catch (error) {
      console.log("Error in adding to cart: ", error);
      setErrorMsg(error.response.data.message);
    }
  };

  const generateDateOptions = () => {
    let today;
    if (JSON.parse(localStorage.getItem("online"))) {
      today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    } else {
      today = new Date();
    }
    const options = [];
    for (let i = 0; i < 3; i++) {
      const date = new Date(today);
      date.setDate(date.getDate() + i);
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        "0"
      )}-${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`;
      console.log(date, formattedDate, "date");
      options.push({ value: formattedDate, label: formattedDate });
    }
    setDateOptions(options);
    setSelectedDate(localStorage.getItem("selected_date") || options[0].value);
  };

  const GetOrderDetails = async () => {
    try {
      const response = await axiosConfig.get(
        `/accounts/order-details/?order=${localStorage.getItem(
          "orderId"
        )}&page_size=1000`
      );
      let order_details = response.data.results.map((item) => ({
        ...item.product,
        quantity: item.quantity,
        max_qty: item.product.max_qty,
        pre_discount_amount: item.pre_discount_amount,
        total_amount: item.total_amount,
        discount_comment: item.discount_comment,
      }));
      setOrderDetails(order_details);
    } catch (error) {
      console.log("Error getting order details", error);
    }
  };
  const GetNews = async () => {
    try {
      const news_response = await axiosConfig.get(`/catlog/Scrolling_News/`);
      const firstDate = new Date(news_response.data.results[0].scrolling_upto);
      const today = new Date();
      const firstDateMidnight = new Date(firstDate.setHours(0, 0, 0, 0));
      const todayMidnight = new Date(today.setHours(0, 0, 0, 0));
      if (firstDateMidnight.getTime() > todayMidnight.getTime()) {
        SetNews({ ...news_response.data.results[0], show_news: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getOrder = async () => {
    try {
      const order_response = await axiosConfig.get(
        `/accounts/orders/${localStorage.getItem("orderId")}/`
      );
      if (order_response.data?.coupon_code) {
        setCouponApplied(order_response.data?.coupon_code);
        let totals = calculateDiscountTotals(order_response.data?.coupon_code);
        setDiscountTotals(totals);
      }
    } catch (error) {
      console.log("Error getting order", error);
    }
  };

  const getCoupons = async () => {
    try {
      const response = await axiosConfig.get(
        "/catlog/coupons/?page_size=200&is_suspended=false"
      );
      getOrder();
      let filtered_coupons = response.data.results.filter(
        (coupon) => coupon.is_display_coupon === true
      );
      setCoupons(filtered_coupons);
      console.log("filtered_coupons", filtered_coupons);
    } catch (error) {
      console.log("Error getting coupons", error);
    }
  };

  const GetStoreData = async () => {
    if (
      JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
      "Conventional"
    )
      return;
    let latitude = localStorage.getItem("latitude");
    let longitude = localStorage.getItem("longitude");
    const root = document.documentElement;
    const primary_color = JSON.parse(localStorage.getItem("site_data"))
      .default_primary
      ? "#3cac47"
      : JSON.parse(localStorage.getItem("site_data")).primary_color;
    const secondary_color = JSON.parse(localStorage.getItem("site_data"))
      .default_secondary
      ? "#e6ffe8"
      : JSON.parse(localStorage.getItem("site_data")).secondary_color + "80";
    root.style.setProperty("--primary_color", primary_color);
    root.style.setProperty("--secondary_color", secondary_color);
    try {
      const response = await axiosConfig.get(
        `/stores/get_store/?latitude=${latitude ? latitude : ""}&longitude=${
          longitude ? longitude : ""
        }`
      );
      localStorage.setItem(
        "slot_duration",
        response.data.Message.slot_duration
      );
    } catch (error) {
      console.log("Error getting store data", error);
    }
  };

  const GenerateTimeSlots = () => {
    let open = localStorage.getItem("opens_at");
    let close = localStorage.getItem("closes_at");
    let duration = localStorage.getItem("slot_duration");
    let slots = [];

    // Parsing open and close times
    let openHours = parseInt(open.substring(0, 2)); // Extracting hours from the time string
    let openMinutes = parseInt(open.substring(3, 5)); // Extracting minutes from the time string
    let closeHours = parseInt(close.substring(0, 2)); // Extracting hours from the time string
    let closeMinutes = parseInt(close.substring(3, 5)); // Extracting minutes from the time string
    let durationHours = parseInt(duration);
    let currentDate;
    if (JSON.parse(localStorage.getItem("online"))) {
      currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    } else {
      currentDate = new Date();
    }
    let currentHours = currentDate.getHours();

    // Generating time slots
    for (let i = openHours; i < closeHours; i += durationHours) {
      let startTime = `${String(i).padStart(2, "0")}:${String(
        openMinutes
      ).padStart(2, "0")}`; // Formatting start time
      let endTimeHours = i + durationHours; // Calculating end time hours
      let endTimeMinutes = openMinutes;
      if (endTimeHours > closeHours) {
        endTimeHours = closeHours;
        endTimeMinutes = closeMinutes;
      }
      let endTime = `${String(endTimeHours).padStart(2, "0")}:${String(
        endTimeMinutes
      ).padStart(2, "0")}`; // Formatting end time
      slots.push(`${startTime} - ${endTime}`);
    }
    setSlots(slots);
    setSlotOptions(
      slots
        .filter(
          (slot) => parseInt(slot.split("-")[0].split(":")[0]) > currentHours
        )
        .map((slot) => ({ value: slot, label: slot }))
    );
    let defaultSlot =
      slots.find((slot) => {
        let slotHours = parseInt(slot.split("-")[0].split(":")[0]);
        return slotHours > currentHours;
      }) || null; // Set defaultSlot to null if no slot meets the condition

    setDefaultSlot(defaultSlot);
    if (defaultSlot === null && deliveryType !== "Express") {
      setErrorMsg(
        "Selected store is closed or slots are not available for today"
      );
    }
  };
  function convertTimestampToTimeRange(timestamp) {
    const date = new Date(timestamp);
    const startTime = date.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    date.setHours(date.getHours() + 3, date.getMinutes() + 50);
    const endTime = date.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${startTime} - ${endTime}`;
  }
  const HandleSlot = (selectedOption, name) => {
    if (name === "date") {
      setSelectedDate(selectedOption.value);
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      const formattedDate = currentDate.toLocaleDateString("en-GB");
      setSlotOptions(
        selectedOption.value === formattedDate.replace(/\//g, "-")
          ? slotOptions.filter(
              (slot) =>
                parseInt(slot.value.split("-")[0].split(":")[0]) > currentHour
            )
          : slots.map((slot) => ({ value: slot, label: slot }))
      );
      setSelectedSlot(
        slotOptions.filter(
          (slot) =>
            parseInt(slot.value.split("-")[0].split(":")[0]) > currentHour
        )[0].value
      );
    } else if (name === "slot") {
      setSelectedSlot(selectedOption.value);
    }
  };

  const GetAddress = async () => {
    if (!localStorage.getItem("address_id")) return;

    // Check if address is already available
    if (Address.id) {
      // If the Address is already fetched, just call GetCharges with the existing Address
      GetCharges(Address);
      return;
    }

    try {
      const response = await axiosConfig.get(
        `/accounts/address/${localStorage.getItem("address_id")}/`
      );
      setAddress(response.data);
      GetCharges(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetCharges = async (add) => {
    const pincode =
      JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
      "Conventional"
        ? add.pincode
        : "";
    const area =
      JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
      "Conventional"
        ? add.landmark
        : "";
    try {
      const payload = {
        stores: localStorage.getItem("storeId"),
        products: products.map((item) => ({
          id: item.id,
          min_qty: item.quantity,
        })),
      };
      console.log(Address, pincode);
      if (pincode) {
        payload.pincode = pincode;
        payload.area = area;
      }
      axiosConfig.defaults.headers["Content-Type"] = "application/json";
      const response = await axiosConfig.post(
        `/accounts/get_delivery_packing_charges/`,
        payload
      );
      let speed_check = products.find(
        (product) => product.speed[0] === "Schedule"
      );
      if (speed_check) {
        setSpeed(false);
      } else {
        setSpeed(true);
      }
      let net_amount = products.reduce((acc, item) => {
        let price = item.offer_price ? item.offer_price : item.price;
        return acc + price * item.quantity;
      }, 0);
      let cod_limit = JSON.parse(localStorage.getItem("site_data")).cod_limit;
      if (cod_limit) {
        setCodAmount(cod_limit);
      }
      setNetAmount(net_amount);
      let coupon_discount_applied = orderDetails.reduce(
        (acc, item) =>
          acc + Math.abs(item.pre_discount_amount - item.total_amount),
        0
      );
      let product_discount = products.reduce(
        (acc, item) =>
          acc +
          Math.abs(
            item.offer_price > 0
              ? (item.price - item.offer_price) * item.quantity
              : 0
          ),
        0
      );
      setDiscount(product_discount);
      setOrder({
        products: products.map((item) => {
          return { id: item.id, min_qty: item.quantity };
        }),
        delivery_type: localStorage.getItem("delivery_type"),
        schedule_date: localStorage
          .getItem("selected_date")
          ?.split("-")
          .reverse()
          .join("-"),
      });
      setCharges(response.data);
      console.log(response.data, "charges");
      if (
        JSON.parse(localStorage.getItem("site_data")).ecommerce_type !==
        "Conventional"
      ) {
        if (
          selectedOption === "Express" &&
          response.data.express_waier_delivery_charges
        ) {
          setDeliveryCharges(0);
          setDeliveryChargesWaived(response.data.express_delivery_charges);
        } else if (selectedOption === "Express") {
          setDeliveryCharges(response.data.express_delivery_charges);
        } else if (
          selectedOption === "DeliveryBy" ||
          selectedOption === "Scheduled"
        ) {
          if (response.data.schedule_waier_delivery_charges) {
            setDeliveryCharges(0);
            setDeliveryChargesWaived(response.data.schedule_delivery_charges);
          } else {
            setDeliveryCharges(response.data.schedule_delivery_charges);
          }
        }
      } else {
        if (response.data.conventional_delivery_waiver_charges) {
          setDeliveryCharges(
            response.data.conventional_delivery_waiver_charges
              ? 0
              : response.data.conventional_delivery_charges
          );
          setDeliveryChargesWaived(response.data.conventional_delivery_charges);
        } else {
          setDeliveryCharges(
            response.data.conventional_delivery_waiver_charges
              ? 0
              : response.data.conventional_delivery_charges
          );
          setDeliveryChargesWaived(0);
        }
      }
      // { response.data.schedule_waier_packing_charges ? setPackingCharges(0) : setPackingCharges(response.data.schedule_packing_charges) }
      if (
        JSON.parse(localStorage.getItem("site_data")).ecommerce_type !==
        "Conventional"
      ) {
        if (response.data.schedule_waier_packing_charges) {
          setPackingCharges(0);
          setPackingChargesWaived(response.data.schedule_packing_charges);
        } else {
          setPackingCharges(response.data.schedule_packing_charges);
        }
      } else {
        if (response.data.conventional_packing_waiver_charges) {
          setPackingCharges(0);
          setPackingChargesWaived(response.data.conventional_packing_charges);
        } else {
          setPackingCharges(response.data.conventional_packing_charges);
          setPackingChargesWaived(0);
        }
      }
      setSelectedLocation({
        lat: JSON.parse(localStorage.getItem("latitude")),
        lng: JSON.parse(localStorage.getItem("longitude")),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentSuccess = async (response) => {
    try {
      let bodyData = new FormData();

      // we will send the response we've got from razorpay to the backend to validate the payment
      bodyData.append("response", JSON.stringify(response));
      console.log(bodyData);
      await axiosConfig
        .post(`/accounts/payment/success/`, bodyData)
        .then(async (res) => {
          console.log("Everything is OK!");
          console.log(res);
          localStorage.removeItem("orderId");
          localStorage.removeItem("state");
          localStorage.removeItem("total");
          localStorage.removeItem("selected_date");
          localStorage.removeItem("selected_slot");
          localStorage.removeItem("delivery_type");
          dispatch(clearCart());
          setPopupType("success");
          setMessage("Order Placed Successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(console.error());
    }
  };

  const NavigateOrders = async () => {
    setLoading(true);
    try {
      axiosConfig.defaults.headers["Content-Type"] = "application/json";
      const response = await axiosConfig.put(
        `/accounts/orders/${localStorage.getItem("orderId")}/`,
        {
          ...Order,
          payment_mode: mode,
          [JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
          "Conventional"
            ? "conventional_date"
            : "schedule_date"]:
            JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
            "Conventional"
              ? new Date(
                  charges.conventional_delivery_days
                    ? charges.conventional_delivery_days
                    : ""
                )
                  ?.split("-")
                  .reverse()
                  .join("-")
              : selectedDate?.split("-").reverse().join("-") ||
                new Date()
                  .toLocaleDateString("en-GB")
                  .replace(/\//g, "-")
                  ?.split("-")
                  .reverse()
                  .join("-"),
          ...(JSON.parse(localStorage.getItem("site_data")).ecommerce_type !==
            "Conventional" && { schedule_slot: selectedSlot || defaultSlot }), // This line adds schedule_slot only if ecommerce_type is not Conventional
          delivery_type:
            JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
            "Conventional"
              ? "Conventional"
              : deliveryType,
          discount_amount: discount,
          coupon_discount:
            couponApplied.length > 0 ? couponApplied[0].coupon_discount : 0,
          delivery_charges: deliveryCharges,
          packing_charges: packingCharges,
          packing_charges_offer:
            packingCharges === 0 ? packingChargesWaived : 0,
          delivery_charges_offer:
            deliveryCharges === 0 ? deliveryChargesWaived : 0,
        }
      );
      const statusUpdateData = {
        orderstatus: "Placed",
      };

      if (
        JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
        "Conventional"
      ) {
        statusUpdateData.conventional_date = new Date(
          charges.conventional_delivery_days
            ? charges.conventional_delivery_days
            : ""
        );
      }
      if (deliveryType === "Express") {
      } else {
        statusUpdateData.schedule_date =
          selectedDate?.split("-").reverse().join("-") ||
          new Date()
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-")
            ?.split("-")
            .reverse()
            .join("-");
        statusUpdateData.schedule_slot = selectedSlot || defaultSlot;
      }

      const status_update = await axiosConfig.post(
        `accounts/orders/${localStorage.getItem(
          "orderId"
        )}/order_status_update/`,
        statusUpdateData
      );
      console.log(response, status_update, "order confirmed");
      setLoading(false);
      localStorage.removeItem("orderId");
      localStorage.removeItem("state");
      localStorage.removeItem("total");
      localStorage.removeItem("selected_date");
      localStorage.removeItem("selected_slot");
      localStorage.removeItem("delivery_type");
      dispatch(clearCart());
      setPopupType("success");
      setMessage("Order Placed Succesfully");
      setErrorMsg(null);
      SetError("");
    } catch (error) {
      console.log(error);
      if (error.response.data.message) {
        setErrorMsg(error.response.data.message);
        SetError(error.response.data.message);
      }
      setLoading(false);
      setPopupType("error");
    }
  };

  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };

  const HandlePayment = async () => {
    setLoading(true);
    try {
      const response = await loadScript();
      let orderId = localStorage.getItem("orderId");
      var convertedValue = parseFloat(orderId);
      let bodyData = {
        order_id: convertedValue,
        payment_mode: mode,
        [JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
        "Conventional"
          ? "conventional_date"
          : "schedule_date"]:
          JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
          "Conventional"
            ? new Date(
                charges.conventional_delivery_days
                  ? charges.conventional_delivery_days
                  : ""
              )
            : selectedDate.split("-").reverse().join("-") ||
              new Date()
                .toLocaleDateString("en-GB")
                .replace(/\//g, "-")
                .split("-")
                .reverse()
                .join("-"),
        ...(JSON.parse(localStorage.getItem("site_data")).ecommerce_type !==
          "Conventional" && { schedule_slot: selectedSlot || defaultSlot }), // This line adds schedule_slot only if ecommerce_type is not Conventional
        delivery_type:
          JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
          "Conventional"
            ? "Conventional"
            : deliveryType,
        products: orderDetails.map((item) => ({
          id: item.id,
          min_qty: item.quantity,
        })),
        delivery_charges: deliveryCharges,
        packing_charges: packingCharges,
        payment_mode: mode,
        discount_amount: parseFloat(discount).toFixed(2),
        coupon_discount: couponApplied[0]
          ? couponApplied[0].coupon_discount
          : 0,
        delivery_charges_offer:
          deliveryCharges === 0 ? deliveryChargesWaived : 0,
        packing_charges_offer: packingCharges === 0 ? packingChargesWaived : 0,
      };
      const data = await axiosConfig.post("/accounts/pay/", bodyData);
      var options = {
        amount: data.data.payment.amount,
        currency: "INR",
        name: "TO OPOTO STORE",
        description: "Test transaction",
        image: "", // add image url
        order_id: data.data.payment.id,
        handler: function (response) {
          handlePaymentSuccess(response);
        },
        prefill: {
          name: localStorage.getItem("name"),
          email: "User's email",
          contact: localStorage.getItem("number"),
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      setLoading(false);
      setErrorMsg(null);
      SetError("");
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.log(error.response.data);
      setPopupType("error");
      setLoading(false);
      if (error.response.data.message) {
        setErrorMsg(error.response.data.message);
        SetError(error.response.data.message);
      }
    }
  };

  const handleCouponClose = () => {
    setCouponCode("");
    setCouponError(null);
  };

  const applyWithCode = async () => {
    try {
      const response = await axiosConfig.post(
        `/accounts/orders/${localStorage.getItem("orderId")}/apply_coupon/`,
        { coupon_code: couponCode }
      );
      setCouponApplied(response.data.order?.coupon_code);
      let totals = calculateDiscountTotals(response.data.order?.coupon_code);
      setDiscountTotals(totals);
      setCouponError(null);
      document.getElementsByClassName("coupon-close")[0]?.click();
      setCouponCode("");
    } catch (error) {
      console.log("Error applying coupon : ", error);
      if (error.response.data.errors) {
        if (error.response.data.errors.length > 0) {
          setCouponError(`${couponCode} - ${error.response.data?.errors[0]}`);
        } else {
          setCouponError(`${couponCode} - "This coupon couldn't be applied"`);
        }
      }
      if (error.response.data.error) {
        setCouponError(error.response.data.error);
      }
      if (error.response.data.order) {
        getOrder();
      }
      if (error.message === "Request failed with status code 500") {
        setCouponError("This coupon couldn't be applied");
      }
    }
  };

  const applyCoupon = async (coupon) => {
    try {
      const response = await axiosConfig.post(
        `/accounts/orders/${localStorage.getItem("orderId")}/apply_coupon/`,
        { coupon_code: coupon.code }
      );
      setCouponApplied(response.data.order?.coupon_code);
      let totals = calculateDiscountTotals(response.data.order?.coupon_code);
      setDiscountTotals(totals);
      setCouponError(null);
      document.getElementsByClassName("coupon-close")[0]?.click();
      setCouponCode("");
    } catch (error) {
      console.log("Error applying coupon : ", error);
      if (error.response?.data) {
        if (error.response.data.errors.length > 0) {
          setCouponError(`${coupon.code} - ${error.response.data?.errors[0]}`);
        } else {
          setCouponError(`${coupon.code} - "This coupon couldn't be applied"`);
        }
      }
      if (error.message === "Request failed with status code 500") {
        setCouponError("This coupon couldn't be applied");
      }
    }
  };

  const RemoveCoupon = async (coupon) => {
    try {
      await axiosConfig.post(`accounts/coupon_offer_remove/`, {
        order_id: localStorage.getItem("orderId"),
        coupon_id: coupon.coupon_applied,
      });
      setCouponApplied([]);
      getOrder();
    } catch (error) {
      console.log("Error removing coupon : ", error);
    }
  };
  useEffect(() => {
    GetOrderDetails();
    generateDateOptions();
    coupons.length === 0 && getCoupons();
    if (
      JSON.parse(localStorage.getItem("site_data")).ecommerce_type !==
      "Conventional"
    ) {
      GenerateTimeSlots();
    }
    GetStoreData();
    GetAddress();
    if (
      CodAmout <=
      products.reduce((a, c) => a + c.price * c.quantity, 0) - discount
    ) {
      if (mode === "cash") {
        setMode(null);
      }
    }
  }, [products]);
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);
  const GetChargesData = async () => {
    try {
      const waiver_response = await axiosConfig.get("/masters/waier-charges/");
      const delivery_response = await axiosConfig.get(
        "/masters/delivery-charges-configuration/"
      );
      const packing_response = await axiosConfig.get(
        "/masters/packing-charges-configuration/"
      );
      console.log(packing_response, "packing_response");
      if (waiver_response.data.results.length > 0) {
        setWaiverData(waiver_response.data.results[0]);
      }
      if (delivery_response.data.results.length > 0) {
        setDeliveryData(delivery_response.data.results[0]);
      }
      if (packing_response.data.results.length > 0) {
        let schedule_packings = packing_response.data.results.filter(
          (p) => p.delivery_type === "Schedule"
        );
        setPackingData(schedule_packings);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetChargesData();
    GetNews();
    const siteData = JSON.parse(localStorage.getItem("site_data"));
    if (siteData && siteData.ecommerce_type === "Conventional") {
      setDeliveryType("");
      setSelectedOption("DeliveryBy");
      setDeliveryOptions(["DeliveryBy"]);
      setOptions(["DeliveryBy"]);
    } else {
      const scheduleDelivery = JSON.parse(
        localStorage.getItem("schedule_delivery")
      );
      const expressDelivery = JSON.parse(
        localStorage.getItem("express_delivery")
      );
      setSchedule(scheduleDelivery);
      setExpress(expressDelivery);
      if (scheduleDelivery && expressDelivery) {
        localStorage.getItem("delivery_type") === "Schedule" &&
        localStorage.getItem("selected_slot")
          ? setSelectedOption("Scheduled")
          : setSelectedOption("Express");
        localStorage.getItem("delivery_type") === "Schedule" &&
        localStorage.getItem("selected_slot")
          ? setScheduleStr(
              `by ${localStorage.getItem(
                "selected_slot"
              )} on ${localStorage.getItem("selected_date")}`
            )
          : setExpressStr(
              `Delivery in ${localStorage.getItem(
                "express_delivery_time"
              )} minutes on ${new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}`
            );
        setOptions(["DeliveryBy", "Express", "Scheduled"]);
      } else if (scheduleDelivery && !expressDelivery) {
        setSelectedOption("Scheduled");
        setDeliveryType("Schedule");
        setScheduleStr(
          `by ${localStorage.getItem(
            "selected_slot"
          )} on ${localStorage.getItem("selected_date")}`
        );
        setOptions(["DeliveryBy", "Scheduled"]);
      } else if (!scheduleDelivery && expressDelivery) {
        setSelectedOption("Express");
        setOptions(["Express"]);
        setExpressStr(
          `Delivery in ${localStorage.getItem(
            "express_delivery_time"
          )} minutes on ${new Date().toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })}`
        );
      } else {
        setOptions([]);
        console.log("");
        setSelectedOption("");
      }
    }
  }, []);
  // Updating the data in local storage so as to freeze it when the user goes back from checkout
  useEffect(() => {
    localStorage.setItem("delivery_type", deliveryType);
    localStorage.setItem("selected_slot", selectedSlot || defaultSlot);
    localStorage.setItem(
      "selected_date",
      selectedDate || new Date().toLocaleDateString("en-GB").replace(/\//g, "-")
    );
  }, [deliveryType, selectedSlot, selectedDate]);
  console.log(delOptions);
  const MyCenteredModal = (props) => {
    return (
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={HandleMiniPopUpClose}
        show={modalShow}
      >
        <Modal.Body className="modal-body-viewCart">
          {modalTitle === "Packing Charges" ? (
            <>
              <h2>Packing Charges</h2>
              {packingData.length > 0 &&
                packingData.map((p) => {
                  return (
                    <p>
                      {Math.round(p.from_kg)}Kg to {Math.round(p.to_kg)}Kg
                      charges ₹{parseFloat(p.charges).toFixed(2)}
                    </p>
                  );
                })}
              {waiverData && (
                <p>
                  ₹0 for order value above{" "}
                  {waiverData.schedule_packing_waier_order_value_above}
                </p>
              )}
            </>
          ) : (
            <>
              {console.log(
                deliveryData.schedule_delivery_charges,
                "deliveryData"
              )}
              <h2>Delivery Charges</h2>
              {deliveryData && (
                <p>
                  {deliveryType === "Express"
                    ? `Express delivery charges  ₹${deliveryData.express_delivery_charges}`
                    : `Schedule delivery charges  ₹${deliveryData.schedule_delivery_charges}`}
                </p>
              )}
              {parseFloat(charges.spike_percentage) > 0 &&
                JSON.parse(localStorage.getItem("site_data")).ecommerce_type !==
                  "Conventional" && (
                  <p>
                    {`${charges.spike_percentage}% spike ${charges.spike_comment}`}
                  </p>
                )}
              {waiverData &&
                (deliveryType === "Express" ? (
                  <p>
                    ₹0 for order value above{" "}
                    {waiverData.express_delivery_waier_order_value_above}
                  </p>
                ) : (
                  <p>
                    ₹0 for order value above{" "}
                    {waiverData.schedule_delivery_waier_order_value_above}
                  </p>
                ))}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer-viewCart">
          <span className="modal-footer-text" onClick={HandleMiniPopUpClose}>
            <b>Sounds good</b>
          </span>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <>
      <div className="grocery-essential">
        <div className="container d-flex justify-content-between">
          <div className="grocery-essential-para">
            {news?.show_news && (
              <marquee direction="right" scrollamount="5">
                {news?.scrolling_point}
              </marquee>
            )}
          </div>
          <ul className="grocery-essential-ul">
            <li className="grocery-essential-li">
              <CiDiscount1 className="icon" />
              <NavLink to="/offers" className="grocery-essential-data">
                OFFERS
              </NavLink>
            </li>

            <li className="grocery-essential-li">
              <PiBookLight className="icon" />
              <NavLink to="/blogs" className="grocery-essential-data">
                BLOGS
              </NavLink>
            </li>
            <li className="grocery-essential-li">
              <CiMobile3 className="icon" />
              <NavLink
                to="https://play.google.com/store/search?q=opoto&c=apps&hl=en"
                className="grocery-essential-data"
              >
                GET APP
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-container">
        <nav className="navbar fixed-top navbar-expand-lg">
          <div className="container-fluid">
            <NavLink to="/" className="navbar-brand">
              {JSON.parse(localStorage.getItem("site_data")).logo ? (
                <img
                  src={
                    JSON.parse(localStorage.getItem("site_data")).logo?.image
                  }
                  alt="logo"
                />
              ) : (
                <div>logo</div>
              )}
            </NavLink>
          </div>
        </nav>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 two scroll">
            <div className="checkout-card f_roboto my-3">
              <div className="rowBetweenCenter p-3">
                <div className="address-text">
                  <div className="f_20_600 mb-3">Delivery Address</div>
                  <div className="f_13_700 mt-2">
                    Name: <span className="f_13_400">{Address.name}</span>
                  </div>
                  <div className="f_13_700">
                    Phone number:{" "}
                    <span className="f_13_400">
                      {JSON.parse(localStorage.getItem("site_data"))
                        .ecommerce_type === "Conventional"
                        ? Address.mobileno
                        : Address.user?.mobileno}
                    </span>
                  </div>
                  <div className="f_13_700">
                    Address:{" "}
                    <span className="f_13_400">{`${Address.flat_no},${
                      Address.address ? `${Address.address},` : ""
                    } ${Address.landmark ? `${Address.landmark}` : ""} `}</span>
                  </div>
                </div>
                <div className="circle-map">
                  <LoadScript
                    googleMapsApiKey="AIzaSyDkOk8E2_1LC7XdwAGlnf_YE84jR30oveQ"
                    libraries={librariesArray}
                  >
                    <GoogleMap
                      mapContainerStyle={{
                        width: "110px",
                        height: "110px",
                        borderRadius: "50%",
                      }}
                      center={selectedLocation || { lat: 19.076, lng: 72.8777 }}
                      clickable={false}
                      draggable={false}
                      zoom={13}
                    ></GoogleMap>
                  </LoadScript>
                </div>
              </div>
              <div className="bg-img">
                <div className="f_20_600 mb-3">Delivery estimate</div>
                <div className={`delivery-options`}>
                  {deliveryOptions &&
                    deliveryOptions.map((option, index) => (
                      <div
                        className={`f_13_400 rowStart pointer ${
                          option !== "DeliveryBy" &&
                          JSON.parse(localStorage.getItem("site_data"))
                            .ecommerce_type === "Conventional"
                            ? "d-none"
                            : ""
                        }`}
                        key={index}
                        style={{
                          color: !delOptions.includes(option) ? "gray" : "",
                          pointerEvents: !delOptions.includes(option)
                            ? "none"
                            : "",
                        }}
                        onClick={() => handleOptionChange(index)}
                      >
                        <div className="circle" style={{ marginTop: "2px" }}>
                          {console.log(selectedOption, "dot")}
                          {selectedOption === option && (
                            <div
                              className="dot"
                              data-bs-toggle={
                                selectedOption === "Scheduled"
                                  ? "offcanvas"
                                  : ""
                              }
                              data-bs-target={
                                selectedOption === "Scheduled"
                                  ? "#offcanvasRight"
                                  : ""
                              }
                              aria-controls={
                                selectedOption === "Scheduled"
                                  ? "offcanvasRight"
                                  : ""
                              }
                            ></div>
                          )}
                        </div>
                        <div className={`ms-2 me-4`}>
                          {option === "DeliveryBy" && (
                            <span>
                              Delivery by{" "}
                              {JSON.parse(localStorage.getItem("site_data"))
                                .ecommerce_type === "Conventional"
                                ? convertTimestampToTimeRange(
                                    charges.conventional_delivery_days
                                  )
                                : defaultSlot}
                              <span> on</span>{" "}
                              {new Date(
                                charges.conventional_delivery_days
                                  ? charges.conventional_delivery_days
                                  : JSON.parse(localStorage.getItem("online"))
                                  ? new Date(
                                      new Date().getTime() + 24 * 60 * 60 * 1000
                                    ) // Adding one day in milliseconds
                                  : new Date()
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })}
                            </span>
                          )}

                          {option === "Express" && (
                            <span>
                              <div>Express delivery</div>
                              {expressStr && (
                                <div className="express-in">{expressStr}</div>
                              )}
                            </span>
                          )}
                          {option === "Scheduled" && (
                            <>
                              <span
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                              >
                                Scheduled delivery{" "}
                                {scheduleStr ? scheduleStr : ""}
                              </span>

                              <div
                                class="offcanvas offcanvas-end"
                                tabindex="-1"
                                id="offcanvasRight"
                                aria-labelledby="offcanvasRightLabel"
                              >
                                <div class="offcanvas-header">
                                  <h5 id="offcanvasRightLabel">
                                    Schedule your delivery
                                  </h5>
                                  <button
                                    type="button"
                                    class="btn-close text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div class="offcanvas-body">
                                  <h3>Pick a time</h3>
                                  {dateOptions.length > 0 && (
                                    <Select
                                      options={dateOptions}
                                      defaultValue={{
                                        label: dateOptions[0]?.label,
                                        value: dateOptions[0]?.value,
                                      }}
                                      placeholder="Pick a date"
                                      className="mb-3"
                                      name="date"
                                      onChange={(selectedOption) =>
                                        HandleSlot(selectedOption, "date")
                                      }
                                    />
                                  )}
                                  {slotOptions.length > 0 && (
                                    <Select
                                      options={slotOptions}
                                      placeholder="Pick a time"
                                      className="mb-5"
                                      defaultValue={{
                                        label: slotOptions.filter(
                                          (slot) =>
                                            parseInt(
                                              slot.value
                                                .split("-")[0]
                                                .split(":")[0]
                                            ) > new Date().getHours()
                                        )[0]?.label,
                                        value: slotOptions.filter(
                                          (slot) =>
                                            parseInt(
                                              slot.value
                                                .split("-")[0]
                                                .split(":")[0]
                                            ) > new Date().getHours()
                                        )[0]?.value,
                                      }}
                                      name="slot"
                                      onChange={(selectedOption) =>
                                        HandleSlot(selectedOption, "slot")
                                      }
                                    />
                                  )}
                                  <button
                                    type="button"
                                    class="btn-primary btn-block btn"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  >
                                    Set
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {JSON.parse(localStorage.getItem("site_data"))
                          .ecommerce_type === "Conventional" ? (
                          <React.Fragment>
                            {option === "DeliveryBy" &&
                              charges.conventional_delivery_waiver_charges && (
                                <strike className="me-2">
                                  ₹{charges.conventional_delivery_charges}
                                </strike>
                              )}
                            <span className="free">
                              {charges.conventional_delivery_waiver_charges
                                ? "waiver"
                                : "₹" + charges.conventional_delivery_charges}
                            </span>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {option === "DeliveryBy" &&
                              charges.schedule_waier_delivery_charges && (
                                <strike className="me-2">
                                  ₹{charges.schedule_delivery_charges}
                                </strike>
                              )}
                            {option === "Express" &&
                              charges.express_waier_delivery_charges &&
                              JSON.parse(
                                localStorage.getItem("express_delivery")
                              ) && (
                                <strike className="me-2">
                                  ₹{charges.express_delivery_charges}
                                </strike>
                              )}
                            {option === "Scheduled" &&
                              charges.schedule_waier_delivery_charges &&
                              JSON.parse(
                                localStorage.getItem("express_delivery")
                              ) && (
                                <strike className="me-2">
                                  ₹{charges.schedule_delivery_charges}
                                </strike>
                              )}
                            <span className="free">
                              {option === "DeliveryBy" &&
                                (charges.schedule_waier_delivery_charges
                                  ? "waiver"
                                  : "₹" + charges.schedule_delivery_charges)}
                              {option === "Express" &&
                                (charges.express_waier_delivery_charges
                                  ? "waiver"
                                  : "₹" + charges.express_delivery_charges)}
                              {option !== "DeliveryBy" &&
                                option !== "Express" &&
                                (charges.schedule_waier_delivery_charges
                                  ? "waiver"
                                  : "₹" + charges.schedule_delivery_charges)}
                            </span>
                          </React.Fragment>
                        )}
                      </div>
                    ))}
                </div>
              </div>
              {/* <div className="p-3">
                                <div className="f_20_600 mb-3">Delivery Instructions</div>
                                <div className="row justify-content-between mt-2">
                                    <div className="instructions col-md-3 mx-auto">
                                        <img src={require("./images/phone.png")} alt="phone" />
                                        <div className="f_13_400 mt-2 text-center">For Delivery Contact below</div>
                                    </div>
                                    <div className="instructions col-md-3 mx-auto">
                                        <img src={require("./images/door.png")} alt="phone" />
                                        <div className="f_13_400 mt-2 text-center">Leave at thedoor</div>
                                    </div>
                                    <div className="instructions col-md-3 mx-auto">
                                        <img src={require("./images/guard.png")} alt="phone" />
                                        <div className="f_13_400 mt-2 text-center">Leave with security</div>
                                    </div>
                                    <div className="instructions col-md-3 mx-auto">
                                        <img src={require("./images/bell.png")} alt="phone" />
                                        <div className="f_13_400 mt-2 text-center">Don”t ring thebell</div>
                                    </div>
                                </div>
                            </div>
                            <div className="delivery-note m-3">
                                <img src={require("./images/note.png")} alt="note" />
                                <div>Add a delivery note here...</div>
                            </div> */}
              {couponApplied &&
              couponApplied.filter(
                (coupon) => coupon.discount_type === "DiscountCoupon"
              ).length > 0 ? (
                <div className="coupon mx-3">
                  {couponApplied
                    .filter(
                      (coupon) => coupon.discount_type === "DiscountCoupon"
                    )
                    .map((coupon, index) => (
                      <div
                        className="rowBetweenCenter gap-10"
                        key={index}
                        style={{ flex: 1 }}
                      >
                        <img
                          src={require("./images/coupons.png")}
                          alt="coupons"
                        />
                        <div>
                          <div className="f_15_600">
                            Coupon Applied Successfully
                          </div>
                          <div className="f_15_400">{coupon.coupon_code}</div>
                        </div>
                        <div
                          className="f_15_600 remove"
                          onClick={() => RemoveCoupon(coupon)}
                        >
                          Remove
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div
                  className="coupon mx-3"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasCoupon"
                  aria-controls="offcanvasCoupon"
                >
                  <div className="rowCenter gap-10">
                    <img src={require("./images/coupons.png")} alt="coupons" />
                    <div>
                      <div className="f_15_600">Apply Coupon</div>
                      <div className="f_13_400">
                        Available offers and discount on your order
                      </div>
                    </div>
                  </div>
                  <MdKeyboardArrowRight size={30} />
                </div>
              )}
              {applyCouponError && (
                <div className="coupon-error">{applyCouponError}</div>
              )}

              <div
                class="offcanvas offcanvas-end"
                tabindex="-1"
                id="offcanvasCoupon"
                aria-labelledby="offcanvasCouponLabel"
              >
                <div class="offcanvas-body coupon-body">
                  <div class="coupon-header">
                    <button
                      type="button"
                      className="btn-close text-reset coupon-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      onClick={handleCouponClose}
                    ></button>
                    <div className="rowCenter">
                      <input
                        type="text"
                        className="coupon-input"
                        placeholder="Enter Coupon Code"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />
                      <button
                        className="coupon-btn"
                        onClick={() => applyWithCode()}
                      >
                        Apply
                      </button>
                    </div>
                    {couponError && (
                      <div className="coupon-error">{couponError}</div>
                    )}
                  </div>
                  <div className="coupon-list">
                    <h6 className="coupon-heading">Available coupons</h6>
                    {coupons.length > 0 &&
                      coupons.map((item) => (
                        <div className="coupon-item">
                          <div className="ticket">
                            <span className="ticket-icon fullCentered">
                              <img
                                src={require("../Components/Footer/images/logo.png")}
                                alt="coupon"
                                className="img-fluid"
                              />
                            </span>
                            <span className="ticket-text fullCentered">
                              {item.code}
                            </span>
                          </div>
                          {/* <h6 className="coupon-amt">Get Flat Rs. 100 off</h6> */}
                          <p>{item.description}</p>
                          {/* <span className="more">+ MORE</span> */}
                          <button
                            className="apply-btn"
                            onClick={() => applyCoupon(item)}
                          >
                            APPLY COUPON
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="f_20_600 my-4 mx-3">Select Payment Method</div>
              {delOptions.length === 0 && (
                <div className="Loginerror text-center">
                  Selected store is closed or slots are not available for today
                </div>
              )}
              <button
                className="payment-mode mx-3"
                onClick={() => setMode("cash")}
                disabled={
                  CodAmout <=
                    parseFloat(
                      products.reduce((a, c) => a + c.price * c.quantity, 0)
                    ) -
                      discount ||
                  products.length === 0 ||
                  selectedOption === ""
                }
              >
                <div className="rowCenter gap-30">
                  <img src={require("./images/cash.png")} alt="cash" />
                  <div>Cash</div>
                </div>
                {mode === "cash" && <MdCheckCircle size={17} color="green" />}
              </button>
              {CodAmout <=
                products.reduce((a, c) => a + c.price * c.quantity, 0) -
                  discount && (
                <div style={{ color: "red", margin: "10px" }}>
                  Cash on Delivery is not available on orders above ₹{CodAmout};
                  please pay online to proceed.
                </div>
              )}
              <button
                className="payment-mode mx-3"
                onClick={() => setMode("online")}
                disabled={products.length === 0 || selectedOption === ""}
              >
                <div className="rowCenter gap-30">
                  <img src={require("./images/online-pay.png")} alt="cash" />
                  <div>Online Payment</div>
                </div>
                {mode === "online" && <MdCheckCircle size={17} color="green" />}
              </button>
              {ErrorMsg && (
                <div className="Loginerror text-center">{ErrorMsg}</div>
              )}
              <div
                className={`confirm-btn pointer mt-4 mx-3 ${
                  mode === null || ErrorMsg ? "disabled" : ""
                }`}
                onClick={mode === "cash" ? NavigateOrders : HandlePayment}
              >
                {loading ? (
                  <img
                    style={{ width: "30px" }}
                    src={require("../Components/images/loader.gif")}
                    alt="loader"
                  />
                ) : (
                  <div>Confirm</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 one f_roboto scroll">
            <div className="rowBetweenCenter">
              <div className="f_20_600">Review Cart</div>
              <div>{orderDetails.length} Items</div>
            </div>
            {orderDetails.length > 0 ? (
              orderDetails.map((item) => (
                <div className="item-container">
                  <div className="rowBetweenStart">
                    <div className="rowCenter gap-30">
                      <div className="img-container">
                        <img src={item?.image?.image} alt="product-img" />
                      </div>
                      <div className="f_15_600 item-name">
                        <div>{item?.name}</div>
                        {item.offer_price && item.offer_price !== "0.00" ? (
                          <div className="rowCenter gap-10">
                            <div className="strike f_15_400">₹{item.price}</div>
                            <div>₹{item.offer_price}</div>
                          </div>
                        ) : (
                          <div>₹{item.price}</div>
                        )}
                      </div>
                    </div>
                    <div className="item-name align-items-end">
                      <div className="btn btn-cart btn-cart-added">
                        <button
                          className="btn-minus"
                          onClick={() => removeFromCart(item)}
                        >
                          <div className="custom-minus"></div>
                        </button>
                        <div className="qty">{item.quantity}</div>
                        <button
                          className="btn-minus"
                          disabled={item.quantity === item.max_qty}
                          onClick={() => addToCart(item)}
                        >
                          <div className="custom-plus"></div>
                        </button>
                      </div>
                      <div className="rowCenter gap-10">
                        {parseFloat(item.pre_discount_amount) >
                          parseFloat(item.total_amount) && (
                          <div className="f_15_400">
                            <strike>₹{item.pre_discount_amount}</strike>
                          </div>
                        )}
                        <div className="f_15_600">
                          {Math.min(
                            parseFloat(item.pre_discount_amount),
                            parseFloat(item.total_amount)
                          ) !== 0
                            ? `₹${Math.min(
                                parseFloat(item.pre_discount_amount),
                                parseFloat(item.total_amount)
                              )}`
                            : `FREE`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="discount-comment">
                    {item.discount_comment}
                  </div>
                </div>
              ))
            ) : (
              <div>
                <div style={{ textAlign: "center" }}>
                  <img
                    className="empty-img"
                    src={require("../Components/Navbar/images/emp_empty_cart.png")}
                    alt="emp"
                    style={{ width: "200px", height: "200px" }}
                  />
                </div>
                <div
                  className={`confirm-btn pointer mt-4 mx-3`}
                  onClick={() => Navigate("/")}
                >
                  Start Shopping
                </div>
              </div>
            )}
            {orderDetails.length > 0 && (
              <div>
                <div className="f_20_600 mt-3">Bill Details</div>
                <div className="rowBetweenCenter f_13_300">
                  <div className="rowCenter">
                    <BsFileTextFill size={14} style={{ marginRight: "6px" }} />{" "}
                    Item total
                    {discount > 0 && (
                      <span className="saved">
                        saved ₹{discount.toFixed(2)}
                      </span>
                    )}
                  </div>
                  <div>
                    {discount > 0 ? (
                      <>
                        <strike>
                          ₹
                          {orderDetails.reduce(
                            (a, c) => a + c.price * c.quantity,
                            0
                          )}
                        </strike>
                        {/* <span className="ms-2">₹{total}</span> */}
                        <span className="ms-2">
                          ₹
                          {orderDetails.reduce(
                            (a, c) => a + parseFloat(c.pre_discount_amount),
                            0
                          ) - parseFloat(discountTotals?.free_item || 0)}
                        </span>
                      </>
                    ) : (
                      <div>
                        ₹
                        {orderDetails.reduce(
                          (a, c) => a + parseFloat(c.pre_discount_amount),
                          0
                        ) - parseFloat(discountTotals?.free_item || 0)}
                      </div>
                    )}
                  </div>
                </div>
                {discountTotals?.bogo_discount > 0 && (
                  <div className="rowBetweenCenter f_13_300">
                    <div>Buy 1 Get 1 discount</div>
                    <div>-₹{discountTotals.bogo_discount.toFixed(2)}</div>
                  </div>
                )}
                {discountTotals?.product_discount > 0 && (
                  <div className="rowBetweenCenter f_13_300">
                    <div>Coupon discount</div>
                    <div>-₹{discountTotals.product_discount.toFixed(2)}</div>
                  </div>
                )}
                <div className="rowBetweenCenter f_13_300">
                  <div className="rowCenter">
                    <RiMotorbikeFill size={14} style={{ marginRight: "6px" }} />{" "}
                    Delivery charges
                    <HiOutlineInformationCircle
                      className="icon-info"
                      onClick={() => {
                        handleShowModal("Delivery Charges");
                      }}
                      size={40}
                    />
                    <MyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                  {JSON.parse(localStorage.getItem("site_data"))
                    .ecommerce_type !== "Conventional" ? (
                    <div>
                      {deliveryCharges === 0 ||
                      parseFloat(discountTotals?.free_delivery_charges) ? (
                        <>
                          <strike>
                            ₹
                            {selectedOption === "DeliveryBy" ||
                            selectedOption === "Scheduled"
                              ? charges.schedule_delivery_charges
                              : charges.express_delivery_charges}
                          </strike>
                          <span className="ms-2">
                            {parseFloat(discountTotals?.free_delivery_charges)
                              ? "Free Delivery"
                              : "waiver"}
                          </span>
                        </>
                      ) : (
                        `₹${deliveryCharges}`
                      )}
                    </div>
                  ) : (
                    <div>
                      {deliveryCharges === 0 ? (
                        <>
                          <strike>
                            ₹{charges.conventional_delivery_charges}
                          </strike>
                          <span className="ms-2">waiver</span>
                        </>
                      ) : (
                        "₹" + deliveryCharges
                      )}
                    </div>
                  )}
                </div>
                <div className="rowBetweenCenter f_13_300">
                  <div className="rowCenter">
                    <MdOutlineShoppingBag
                      size={14}
                      style={{ marginRight: "6px" }}
                    />{" "}
                    Packing charges
                    <HiOutlineInformationCircle
                      className="icon-info"
                      onClick={() => {
                        handleShowModal("Packing Charges");
                      }}
                      size={40}
                    />
                  </div>
                  {JSON.parse(localStorage.getItem("site_data"))
                    .ecommerce_type !== "Conventional" ? (
                    <div>
                      {packingCharges === 0 ? (
                        <>
                          <strike>
                            {console.log(
                              "packing charges",
                              charges.schedule_packing_charges
                            )}
                            ₹
                            {selectedOption === "DeliveryBy" ||
                            selectedOption === "Scheduled"
                              ? charges.schedule_packing_charges
                              : charges.express_packing_charges}
                          </strike>
                          <span className="ms-2">waiver</span>
                        </>
                      ) : (
                        "₹" + packingCharges
                      )}
                    </div>
                  ) : (
                    <div>
                      {packingCharges === 0 ? (
                        <>
                          <strike>
                            ₹{charges.conventional_packing_charges}
                          </strike>
                          <span className="ms-2">waiver</span>
                        </>
                      ) : (
                        "₹" + packingCharges
                      )}
                    </div>
                  )}
                </div>
                <div className="grand-total">
                  <div className="f_13_700">Grand total</div>
                  <div className="grand-total">
                    ₹
                    {orderDetails.reduce(
                      (acc, item) => acc + item.price * item.quantity,
                      0
                    ) -
                      orderDetails.reduce(
                        (acc, item) =>
                          acc +
                          (item.offer_price > 0
                            ? item.price - item.offer_price
                            : 0) *
                            item.quantity,
                        0
                      ) +
                      (discountTotals?.free_delivery_charges
                        ? 0
                        : deliveryCharges) +
                      packingCharges -
                      (couponApplied
                        ? couponApplied
                            .filter(
                              (item) =>
                                item.discount_type !==
                                "FreeDeliveryChargesCoupon"
                            )
                            .reduce(
                              (acc, item) =>
                                acc + parseFloat(item.coupon_discount),
                              0
                            )
                        : 0)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {popupType != "" && (
        <PopupModal
          popupType={popupType}
          setPopupType={setPopupType}
          error={Error}
          Message={message}
          Navigation={"/account/orders"}
        />
      )}
      <MyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};
export { Checkout };
