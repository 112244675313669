import React, { useEffect, useState } from "react";
import "./Footer.css";
import axiosConfig from "../../Service/axiosConfig";
import { useNavigate, NavLink } from "react-router-dom";
import { MdOutlineFacebook } from "react-icons/md";
import { BsInstagram } from "react-icons/bs";
import { AiOutlineLinkedin } from "react-icons/ai";
import { IoLogoYoutube } from "react-icons/io";
import { FaSquareTwitter } from "react-icons/fa6";
import { PiMapPinLight } from "react-icons/pi";
const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [Data, SetData] = useState([]);
  const [Email, Setemail] = useState({});
  const [show, setShow] = useState(false);
  const [Error, setError] = useState("");
  const [siteData, setSiteData] = useState({});
  const [policy, setPolicy] = useState({});
  const [blogs, setBlogs] = useState([]);

  const GetData = async () => {
    setLoading(true);
    try {
      const responce = await axiosConfig.get(
        "/catlog/categories/?page_size=30&is_suspended=false"
      );
      SetData(responce.data.results);
      localStorage.setItem("categories", JSON.stringify(responce.data.results));
      setSiteData(JSON.parse(localStorage.getItem("site_data")));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const policies = async () => {
    setLoading(true);
    try {
      const responce = await axiosConfig.get("/catlog/policies/");
      localStorage.setItem(
        "policies",
        JSON.stringify(responce.data.results[0])
      );
      const blog_response = await axiosConfig.get(`/blogapp/blog/`);
      setBlogs(blog_response.data.results);
      setPolicy(responce.data.results[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    policies();
  }, []);
  const divideIntoColumns = (data, numColumns) => {
    const dividedData = [];
    const columnSize = Math.ceil(data.length / numColumns);

    for (let i = 0; i < data.length; i += columnSize) {
      dividedData.push(data.slice(i, i + columnSize));
    }

    return dividedData;
  };

  const renderColumns = (data) => {
    return data.map((column, index) => {
      return (
        <div className="col-md-4 col-6">
          <ul className="list-unstyled">
            {column.map((item, itemIndex) => {
              return (
                <li key={itemIndex}>
                  <NavLink to={`/${item.slug}`}>{item.name}</NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  };
  const HandleSubscribe = (e) => {
    const { name, value } = e.target;
    Setemail({ ...Email, [name]: value });
  };
  const GetEmail = async () => {
    try {
      const response = await axiosConfig.post(`/masters/subscribe/`, Email);
      console.log(response);
      setError("Success: Your e-mail has been subscribed successfully!");
    } catch (error) {
      setShow(true);
      console.log(error.response.data.email[0]);
      if (error.response.data.email[0] === "Enter a valid email address.") {
        setError("Invalid Email Address!");
      }
      if (
        error.response.data.email[0] ===
        "subscribe with this email already exists."
      ) {
        setError("Email already exists!");
      }
    }
  };

  const dividedColumns = divideIntoColumns(Data, 4);
  const GetStores = async () => {
    if (stores.length > 0) return;
    try {
      const response = await axiosConfig.get(
        `/stores/stores/?page=1&page_size=20`
      );
      setStores(response.data.results);
    } catch (error) {
      console.log("Error getting stores", error);
    }
  };
  useEffect(() => {
    GetData();
    GetStores();
  }, []);
  const [visibleCount, setVisibleCount] = useState(6);
  const hasMoreLocations = stores.length > visibleCount;

  const handleSeeMoreClick = () => {
    setVisibleCount((prevCount) => prevCount + 6);
  };
  return (
    <>
      <div className="footer-container">
        <div className="container">
          <div className="site-links">
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <h5>Useful Links</h5>
                  <div className="col-6">
                    <ul className="list-unstyled">
                      {policy?.about_us !== "<h3><br></h3>" && (
                        <li>
                          <NavLink to="/aboutus">About Us</NavLink>
                        </li>
                      )}
                      <li>
                        <NavLink to="/contactus">Contact Us</NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className="col-6">
                    <ul className="list-unstyled">
                      {policy?.privacy_policy !== "<h3><br></h3>" &&
                        policy?.privacy_policy && (
                          <li>
                            <NavLink to="/privacy">Privacy Policy</NavLink>
                          </li>
                        )}
                      {policy?.terms_conditions !== "<p><br></p>" &&
                        policy?.terms_conditions && (
                          <li>
                            <NavLink to="/terms-services">
                              Terms &amp; Conditions
                            </NavLink>
                          </li>
                        )}
                      {policy?.faq !== "<p><br></p>" && policy?.faq && (
                        <li>
                          <NavLink to="/faqs">FAQs</NavLink>
                        </li>
                      )}
                      {policy?.return_policy !== "<p><br></p>" &&
                        policy?.return_policy && (
                          <li>
                            <NavLink to="/return-policy">Return Policy</NavLink>
                          </li>
                        )}
                      {policy?.cancellation !== "<p><br></p>" &&
                        policy?.cancellation && (
                          <li>
                            <NavLink to="/cancellation">Cancellation</NavLink>
                          </li>
                        )}
                      <li>
                        <NavLink to="/blogs">Blogs</NavLink>
                      </li>
                      <li>
                        <NavLink to="/brands">Brands</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <h5>Categories</h5>
                  {renderColumns(dividedColumns)}
                </div>
              </div>
              {/* <div className="col-md-2">
                <h5>Our Stores</h5>
                {stores.length > 0 &&
                  stores.map((store) => {
                    return (
                      <div key={store.id} style={{ display: "flex" }}>
                        <PiMapPinLight size={20} className="map-pin" />
                        <div>
                          <p
                            style={{
                              color: "#666666",
                              textDecoration: "auto",
                              fontSize: "14px",
                            }}
                          >
                            {store.name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div> */}
            </div>
          </div>
          {siteData?.ecommerce_type !== "Conventional" && (
            <div className="d-flex flex-column location-footer">
              <h5>Our locations</h5>
              <ul className="list-unstyled d-flex flex-wrap">
                {stores.slice(0, visibleCount).map((location, index) => (
                  <li key={index} className="location-item">
                    {location.store_location}
                  </li>
                ))}
              </ul>
              {hasMoreLocations && visibleCount < stores.length && (
                <p className="see-more ms-3" onClick={handleSeeMoreClick}>
                  See More...
                </p>
              )}
            </div>
          )}

          <div className="brand-footer">
            <div className="row">
              <div className="col-md-4">
                {siteData?.copy_right && (
                  <p className="copyright">
                    Copyright &copy; {JSON.parse(siteData?.copy_right)?.website}
                    , 2024 All Rights Reserved.
                    {/* India Private
                    Limited, 2016-2023 */}
                  </p>
                )}
              </div>
              <div className="col-md-4">
                {siteData?.android_link && (
                  <NavLink
                    className="downloadapp"
                    target="blank"
                    to={siteData?.android_link}
                  >
                    Download App <img src={require("./images/android.webp")} />
                  </NavLink>
                )}
              </div>
              <div className="col-md-4">
                <div className="social-icons">
                  {siteData?.facebook && (
                    <img
                      src={require("./images/facebook.png")}
                      className="soc-icon"
                      onClick={() => window.open(siteData.facebook, "_blank")}
                    />
                  )}
                  {siteData?.instagram && (
                    <img
                      src={require("./images/instagram.png")}
                      className="soc-icon"
                      onClick={() => window.open(siteData.instagram, "_blank")}
                    />
                  )}
                  {siteData?.linkedn && (
                    <img
                      src={require("./images/linkedin.png")}
                      className="soc-icon"
                      onClick={() => window.open(siteData.linkedn, "_blank")}
                    />
                  )}

                  {siteData?.youtube && (
                    <img
                      src={require("./images/Youtube.jpg")}
                      className="soc-icon"
                      onClick={() => window.open(siteData.youtube, "_blank")}
                    />
                  )}
                  {siteData?.twitter && (
                    <img
                      src={require("./images/Twitter.jpg")}
                      className="soc-icon"
                      onClick={() => window.open(siteData.twitter, "_blank")}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {siteData?.copy_right && (
            <div className="footer-bottom">
              <p className="text-powered">
                Developed and Maintained by{" "}
                <a
                  href={
                    siteData.maintained_by
                      ? `https://${siteData.maintained_by}`
                      : "#"
                  }
                  target="_blank"
                >
                  {siteData.maintained_by}
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export { Footer };
