import React, { useState, useEffect } from "react";
import "../Components/Navbar/Navbar.css"
import axiosConfig from "../Service/axiosConfig"
import { useSelector } from "react-redux";
import { selectCartTotal } from "../redux/Slices/cartSlice";
import { MdShoppingCart, MdOutlineHome, MdSearch, MdOutlineAddCircleOutline, MdWorkOutline, MdOtherHouses } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { BiLeftArrowAlt } from "react-icons/bi"
import { ViewCart } from "../Components/Navbar/ViewCart"
import { NavLink, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { Login } from "../Components/Login/Login";
import { AddressModal, LocationModalNavBar, AccessDeny, PopupModal } from "../Components/PopupModal";
import Product from "../Components/Products/Product";
import { HiOutlineArrowLeft } from "react-icons/hi";
import Masters from "../Components/masters.json";
import { FormParser } from "../Components/FormParser/FormParser";
import useDebouncedValue from "../Utils/Debounce";
import ViewCartModal from "../Components/Navbar/ViewCartModal";
import AddAddress from "../Components/PopupModal/AddAddress";
import { CiDiscount1, CiShop, CiMobile3 } from "react-icons/ci";
import { PiBookLight } from "react-icons/pi";
const SearchProducts = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [LocationNavBarType, setLocatioNavBarType] = useState("")
    const search = searchParams.get("query");
    const Navigate = useNavigate();
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const { cart } = useSelector((state) => state);
    const total = useSelector(selectCartTotal);
    const [show, setShow] = useState(false);
    const [LoggedIn, setLoggedIn] = useState(false);
    const [loginShow, setloginShow] = useState(false);
    const [query, setQuery] = useState(search);
    const debouncedSearchTerm = useDebouncedValue(query, 500);
    const [count, setCount] = useState(1)
    const handleClose = () => setShow(false);
    const handleLoginClose = () => setloginShow(false);
    const handleShow = () => setShow(true);
    const handleLoginShow = () => setloginShow(true);
    const [popupType, setPopupType] = useState("")
    const [Address, setAddress] = useState([])
    const [news, SetNews] = useState(null)
    const [orderAddress, setOrderAddress] = useState()
    const [storeNull, setStoreNull] = useState(false)
    const [ActiveUrl, setActiveUrl] = useState("")
    const [Data, setData] = useState([])
    const [loading, setLoading] = useState("")
    const [loading2, setLoading2] = useState("")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [NoStock, setNoStock] = useState([])
    const [number, setNumber] = useState({})
    const [Step, SetStep] = useState("1")
    const [smShow, setSmShow] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [Error, SetError] = useState("")
    const [Totalamount, setTotalAmount] = useState({
        Total_amount: 0
    })
    const [addressModal, setAddressModal] = useState(false)
    const [RelatedKeywords, setRelatedKeywords] = useState([])
    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    const [NoAccess, setNoAccess] = useState("")
    let ProductData = []
    const initialOrderState = {
        "address": "",
        "products": [],
        "stores": localStorage.getItem("stores"),
        "user_id": localStorage.getItem("userId"),
        "packing_charges": 0,
        "delivery_charges": 0,
    };
    const scheduleDate = localStorage.getItem("selected_date")?.split("-").reverse().join("-");
    if (scheduleDate) {
        initialOrderState.schedule_date = scheduleDate;
    }
    const [Order, setOrders] = useState(initialOrderState);
    const [message, setMessage] = useState(null)
    const MastersModal = JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Hyperlocal" ? Masters.AddAddressHyperlocal : Masters.AddAddress
    let page = 1;
    let totalPages;

    // let search_word = window.location.href.split("=")[1];
    // console.log(search_word, "url.....")

    async function handleInputChange(event) {
        const inputValue = event.target.value;
        setQuery(inputValue);
        setSearchParams({ query: inputValue });
    }
    async function handleInput(event) {
        setQuery(event);
        setSearchParams({ query: event });
    }

    const handleInfiniteScroll = async (event) => {
        try {
            console.log(event, search)
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                if (page <= totalPages) {
                    console.log(page)
                    console.log("inside infinite scroll...")

                    console.log(totalPages, "total pages....")
                    setLoading2(true)
                    page = page + 1
                    let resp = await axiosConfig.get(`/catlog/products/search/?search_query=${query}&page_size=20&page=${page}&store=${localStorage.getItem("storeId")}&is_suspended=false`)
                    console.log(resp)
                    setData((Data) => [...Data, ...resp.data.results?.results])
                    setLoading2(false)

                }
            }

        } catch (error) {
            console.log(error)
            setLoading2(false)
        }
    }
    const ActiveData = (activeUrl) => {
        localStorage.setItem("activeurl", activeUrl)
    }
    const HndleSmallShow = () => {
        setSmShow(false)
        setNoStock("")
        setErrorMsg(null)
    }
    const HandleModal = () => {
        SetStep(1)
        setNumber({})
    }
    const GetlocaData = () => {
        const Accounturl = localStorage.getItem("activeurl")
        setActiveUrl(Accounturl)
    }
    function handleSubmit(event) {
        event.preventDefault();
    }

    function handleLoginEvent() {
        handleLoginClose(false);
        setLoggedIn(true)
    }
    const CheckLogin = async () => {
        if (localStorage.getItem('token')) {
            setLoggedIn(true)
        }
    }

    const handlePaymentSuccess = async (response) => {
        try {
            let bodyData = new FormData();

            // we will send the response we've got from razorpay to the backend to validate the payment
            bodyData.append("response", JSON.stringify(response));

            await axiosConfig.post(`/accounts/payment/success/`, bodyData)
                .then((res) => {
                    console.log("Everything is OK!");
                    setName("");
                    setAmount("");
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            console.log(console.error());
        }
    };
    const loadScript = () => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        document.body.appendChild(script);
    };
    const showRazorpay = async () => {
        const res = await loadScript();
        let bodyData = new FormData();
        bodyData.append("amount", total.toString());
        const data = await axiosConfig.post("/accounts/pay/", bodyData,).then((res) => {
            return res;
        }).catch((error) => {
            console.log(error)
        });
        var options = {
            // key_id: process.env.REACT_APP_PUBLIC_KEY, // in react your environment variable must start with REACT_APP_
            // key_secret: process.env.REACT_APP_SECRET_KEY,
            amount: data.data.payment.amount,
            currency: "INR",
            name: "TO OPOTO STORE",
            description: "Test teansaction",
            image: "", // add image url
            order_id: data.data.payment.id,
            handler: function (response) {
                // we will handle success by calling handlePaymentSuccess method and
                // will pass the response that we've got from razorpay
                handlePaymentSuccess(response);
            },
            prefill: {
                name: localStorage.getItem("name"),
                email: "User's email",
                contact: localStorage.getItem("number"),
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
    const GetTheAddress = () => {
        setCount(2)
        GetData()
    }
    const PayMentNavigate = async () => {
        let order_obj = Order;
        let request_type = 'post';
        let url = '/accounts/orders/';
        if (localStorage.getItem("orderId")) {
            order_obj['order_id'] = localStorage.getItem("orderId")
            request_type = 'put';
            url = `/accounts/orders/${localStorage.getItem("orderId")}/`;
        }
        try {
            axiosConfig.defaults.headers['Content-Type'] = 'application/json';
            const res = await axiosConfig[request_type](url, Order);
            localStorage.setItem("orderId", res.data.id);
            Navigate("/checkout");
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data.message) {
                setSmShow(true);
                setErrorMsg(error.response.data.message);
            }
            if (error.response && error.response.data["The following products are not available in the store:"]) {
                error.response.data["The following products are not available in the store:"].forEach(async (res) => {
                    try {
                        const productRes = await axiosConfig.get(`/catlog/products/${res.id}/?is_suspended=false`);
                        setNoStock(prestate => [...prestate, productRes.data]);
                        setSmShow(true);
                    } catch (error) {
                        console.error(error);
                    }
                });
            }
        }
    }
    const HandleAddress = () => {
        setAddressModal(true)
    }

    const closeAddressModal = () => {
        setAddressModal(false)
        setErrorMsg(null)
        SetError("")
    }

    const formSubmit = async (Data) => {
        try {
            axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
            const store_response = await axiosConfig.get(`/stores/get_store/?latitude=${Data.latitude}&longitude=${Data.longitude}`);
            if (store_response.data.Message.store_id !== null) {
                const res = await axiosConfig.post(`/accounts/address/`, { ...Data, "user": localStorage.getItem("userId") });
                console.log(res, "address added")
                setPopupType("success")
                setAddressModal(false)
                setMessage("Address added successfully")
                GetData()
            } else if (store_response.data.Message.store_id === null && localStorage.getItem("site_data").ecommerce_type === "Conventional") {
                const res = await axiosConfig.post(`/accounts/address/`, { ...Data, "user": localStorage.getItem("userId") });
                console.log(res, "address added")
                setPopupType("success")
                setAddressModal(false)
                setMessage("Address added successfully")
                GetData()
            } else {
                setPopupType("")
                SetError("No service Available at this location")
                setMessage("No service Available at this location")
            }
        } catch (error) {
            console.error(error);
            SetError("error adding address")
            setPopupType("")
        }
    }
    const GetData = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/address/?user=${localStorage.getItem("userId")}&is_suspended=false`)
            setAddress(response.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }

    const GetNews = async () => {
        try {
            const news_response = await axiosConfig.get(`/catlog/Scrolling_News/`);
            const firstDate = new Date(news_response.data.results[0].scrolling_upto);
            const today = new Date();
            const firstDateMidnight = new Date(firstDate.setHours(0, 0, 0, 0));
            const todayMidnight = new Date(today.setHours(0, 0, 0, 0));
            if (firstDateMidnight.getTime() > todayMidnight.getTime()) {
                SetNews({ ...news_response.data.results[0], "show_news": true })
            }

        }
        catch (error) {
            console.log(error)
        }
    }

    const GetAddress = async (id) => {
        try {
            if (JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional") { return null }
            const response = await axiosConfig.get(`/accounts/address/${id}/`)
            const store_response = await axiosConfig.get(`/stores/get_store/?latitude=${response.data.latitude}&longitude=${response.data.longitude}`);
            if (store_response.data.Message.store_id !== null && JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Hyperlocal") {
                localStorage.setItem("online", store_response.data.Message.is_online === "offline" ? true : false)
                localStorage.setItem("storeId", store_response.data.Message.store_id);
                localStorage.setItem("opens_at", store_response.data.Message.opens_at);
                localStorage.setItem("closes_at", store_response.data.Message.closes_at);
                localStorage.setItem("slot_duration", store_response.data.Message.slot_duration);
                // window.location.reload();
                setOrderAddress(response.data)
                localStorage.setItem("name", response.data.name);
                localStorage.setItem("address_id", response.data.id);
                localStorage.setItem("address", response.data.address)
                localStorage.setItem("latitude", response.data.latitude)
                localStorage.setItem("longitude", response.data.longitude)
                if (store_response.data.Message.is_online === "offline") {
                    localStorage.setItem("express_delivery", false)
                    localStorage.removeItem("express_delivery_time")
                }
                else {
                    localStorage.setItem("express_delivery", store_response.data.Message.express_delivery)
                    if (store_response.data.Message.express_delivery && store_response.data.Message.express_delivery_time) {
                        localStorage.setItem("express_delivery_time", store_response.data.Message.express_delivery_time)
                    }
                    else {
                        localStorage.removeItem("express_delivery_time")
                    }
                }
                cart.products.forEach(res => {
                    console.log(res)
                    ProductData.push({
                        "id": res.id,
                        "min_qty": res.quantity,
                    })
                })
                setOrders(
                    {
                        ...Order,
                        "stores": localStorage.getItem("storeId"), "user_id": parseFloat(localStorage.getItem("userId")), "address": id,
                        "products": ProductData,
                    }
                )
                setStoreNull(false)
            } else if (JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional") {
                setOrderAddress(response.data)
                localStorage.setItem("name", response.data.name)
                localStorage.setItem("address_id", response.data.id)
                localStorage.setItem("address", response.data.address)
                localStorage.setItem("latitude", response.data.latitude)
                localStorage.setItem("longitude", response.data.longitude)
                cart.products.forEach(res => {
                    console.log(res)
                    ProductData.push({
                        "id": res.id,
                        "min_qty": res.quantity,
                    })
                })
                setOrders(
                    {
                        ...Order,
                        "stores": localStorage.getItem("storeId"), "user_id": parseFloat(localStorage.getItem("userId")), "address": id,
                        "products": ProductData,
                    }
                )
            } else {
                setStoreNull(true)
            }
        }
        catch (error) {
            console.log(error)
        }

    }

    const ChangeAddress = () => {
        setCount(2)
        setOrderAddress()
    }
    const newAddressAdded = () => {
        GetData();
    }
    const MovetoCart = () => {
        setCount(1)
        setOrderAddress()
        setStoreNull(false)
    }
    const handleNavigator = (event) => {
        event.preventDefault();
        Navigate("/")
    }
    const AddressLocal = async (id) => {
        try {
            const response = await axiosConfig.get(`/accounts/address/${localStorage.getItem("address_id")}/`)
            setOrderAddress(response.data)
            cart.products.forEach(res => {
                console.log(res)
                ProductData.push({
                    "id": res.id,
                    "min_qty": res.quantity,
                })
            })
            setOrders(
                {
                    ...Order,
                    "stores": localStorage.getItem("storeId"), "user_id": parseFloat(localStorage.getItem("userId")), "address": localStorage.getItem("address_id"),
                    "products": ProductData,
                }
            )
        }
        catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        if (localStorage.getItem("address_id")) {
            AddressLocal()
        }
        GetData();
        GetNews();
        CheckLogin();
        GetlocaData();
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }


    }, [])

    useEffect(() => {
        const getSearchResults = async () => {
            setLoading(true);

            if (debouncedSearchTerm === "" || debouncedSearchTerm === null) {
                console.log("vvvv")
                setData([]);
                setLoading(false);
            } else {
                try {
                    const response = await axiosConfig.get(
                        `/catlog/products/search/?search_query=${debouncedSearchTerm}&page_size=20&page=${page}&store=${localStorage.getItem("storeId")}&is_suspended=false`
                    );
                    totalPages = Math.ceil(response.data.count / 20);
                    setData(response.data.results?.results);
                    let uniqueVegetables = [...new Set(response.data.results?.related_keywords)];
                    setRelatedKeywords(uniqueVegetables)
                    setLoading(false);
                    handleInfiniteScroll(); // Assuming handleInfiniteScroll is defined somewhere
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                }
            }
        };

        getSearchResults();
    }, [debouncedSearchTerm]);

    console.log(RelatedKeywords)
    return (
        <>
            {
                isMobile ? <div>
                    <form className="form-nav-input position-relative " onSubmit={handleSubmit}>
                        {/* <NavLink to="/s" className="position-relative"> */}
                        <div className="form-controll">
                            <BiLeftArrowAlt className="search-icon" onClick={handleNavigator} />
                            <input type="text" autoFocus value={query} onChange={handleInputChange} />
                        </div>
                        {/* </NavLink> */}
                    </form>
                </div> :
                    <>
                        <div className="grocery-essential">
                            <div className="container d-flex justify-content-between">
                                <div className="grocery-essential-para">
                                    {news?.show_news &&
                                        <marquee direction="right" scrollamount="5">
                                            {news?.scrolling_point}
                                        </marquee>
                                    }
                                </div>
                                <ul className="grocery-essential-ul">
                                    <li className="grocery-essential-li">
                                        <CiDiscount1 className="icon" />
                                        <NavLink to="/offers" className="grocery-essential-data">
                                            OFFERS
                                        </NavLink>
                                    </li>
                                    <li className="grocery-essential-li">
                                        <PiBookLight className="icon" />
                                        <NavLink to="/blogs" className="grocery-essential-data">
                                            BLOGS
                                        </NavLink>
                                    </li>
                                    <li className="grocery-essential-li">
                                        <CiMobile3 className="icon" />
                                        <NavLink to="https://play.google.com/store/search?q=opoto&c=apps&hl=en" className="grocery-essential-data">
                                            GET APP
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="navbar-container">
                            <nav className="navbar fixed-top navbar-expand-lg ">
                                <div className="container-fluid">
                                    <NavLink to="/" className="navbar-brand" >
                                        {JSON.parse(localStorage.getItem("site_data")).logo ?
                                            <img src={JSON.parse(localStorage.getItem("site_data")).logo.image} alt="logo" />
                                            :
                                            <p>logo</p>
                                        }
                                    </NavLink>
                                    <div className="v-divider"></div>
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse d-flex justify-content-between" id="navbarSupportedContent">
                                        <form className="form-nav-input position-relative" onSubmit={handleSubmit} >
                                            {/* <NavLink to="/s"> */}
                                            <div className="form-search">
                                                <MdSearch className="search-icon" />
                                                <input autoFocus type="text" placeholder="Search for atta dal and more" className="form-control" value={query} onChange={handleInputChange} />
                                            </div>
                                            {/* </NavLink> */}
                                        </form>
                                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                            <li className="nav-item">
                                                <div className="btn btn-success cart-container show-desktop" onClick={handleShow}>
                                                    <div className="cart-icon-holder">
                                                        <MdShoppingCart />
                                                    </div>
                                                    <div className="cart-qty">
                                                        {cart.products.length > 0 ? (
                                                            <p>{cart.products.length} items</p>
                                                        ) : (
                                                            <p>My Cart</p>
                                                        )}
                                                        <span className="p-price">₹{parseFloat(total).toFixed(2)}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                            <div className="navbar-bottom"></div>
                        </div>
                    </>
            }

            {cart.products.length > 0 &&
                <div className="btn btn-success cart-container show-tablet" onClick={handleShow}>
                    <div className="cart-icon-holder">
                        <MdShoppingCart />
                    </div>
                    <div className="cart-qty">
                        {cart.products.length > 0 ? (
                            <p>{cart.products.length} items</p>
                        ) : (
                            <p>My Cart</p>
                        )}
                    </div>
                    <span className="p-price">₹{parseFloat(total).toFixed(2)}</span>
                </div>
            }

            <div className="search-container">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <>
                        {Data.length > 0 &&
                            <>
                                {
                                    RelatedKeywords && RelatedKeywords.map((res, ind) => {
                                        return (
                                            <div className="hnxUxX" onClick={() => handleInput(res)}>
                                                <span className="cWRDMW">
                                                    {res}
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                                <p className="showing-results">Showing results for "{query}"</p>
                                <div className="catagery-items-grid">
                                    {
                                        Data.map((itemObj, index) => {
                                            return (
                                                <Product item={itemObj} index={index} />
                                            )
                                        })
                                    }
                                </div>
                                {/* another loader */}
                                {loading2 && (
                                    <div className="loader-container">
                                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                                    </div>
                                )}
                            </>
                        }
                    </>
                )}
            </div>


            <ViewCartModal
                count={count}
                setCount={setCount}
                show={show}
                setShow={setShow}
                handleClose={handleClose}
                orderAddress={orderAddress}
                setOrderAddress={setOrderAddress}
                setOrders={setOrders}
                Order={Order}
                ProductData={ProductData}
                setTotalAmount={setTotalAmount}
                ChangeAddress={ChangeAddress}
                GetTheAddress={GetTheAddress}
                PayMentNavigate={PayMentNavigate}
                handleLoginShow={handleLoginShow}
                cart={cart}
                Totalamount={Totalamount}
                isMobile={isMobile}
                MovetoCart={MovetoCart}
                HandleAddress={HandleAddress}
                storeNull={storeNull}
                Address={Address}
                GetAddress={GetAddress}
                travelTime={localStorage.getItem("express_delivery_time")}

            />

            <Modal
                size="sm"
                show={smShow}
                onHide={HndleSmallShow}
                aria-labelledby="example-modal-sizes-title-sm"
                centered
            >
                <Modal.Body>
                    {Error ? <> <div style={{ color: "red" }}>{Error}</div></> :
                        NoStock.length >= 1 ? NoStock.map((res, i) => {
                            console.log(res)
                            return (
                                <div key={i} className="p-avalibile" >
                                    {res.name}<br />
                                </div>
                            )
                        }) : <div className="p-avalibile">
                            {NoStock.name}
                        </div>
                    }
                    {!errorMsg &&
                        <div className="not-avalible text-center">
                            Not Available in Selected Store
                        </div>
                    }
                    {errorMsg &&
                        <div className="min-value-error">{errorMsg}</div>
                    }
                </Modal.Body>
                <Modal.Footer >
                    <button className="btn btn-secondary" onClick={HndleSmallShow}>Ok</button>
                </Modal.Footer>
            </Modal>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                <Modal.Header closeButton>
                    {number.mobile ? <Modal.Title className="modal-tile-back" onClick={HandleModal}>Back</Modal.Title> : <Modal.Title className="login-head__text weight--light">Phone Number Verification</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    <div className="login-head">
                        <h2 className="login-head__text weight--light">Phone Number Verification</h2>
                    </div>
                    <Login handleLoginEvent={handleLoginEvent} setNumber={setNumber} number={number} SetStep={SetStep} Step={Step} />
                    <div className="loginfooter">
                        <p>By continuing, you agree to our</p>
                        <NavLink to="/terms-services">Terms of service</NavLink>
                        <NavLink to="/privacy">Privacy policy</NavLink>
                    </div>
                </Modal.Body>
            </Modal>
            {/* {
                popupType === "success" &&
                <AddressModal popupType={popupType} newAddressAdded={newAddressAdded} setPopupType={setPopupType} />
            } */}
            <AddAddress addressModal={addressModal} closeAddressModal={closeAddressModal} MastersModal={MastersModal} setPopupType={setPopupType} GetData={GetData} formSubmit={formSubmit} Error={Error} />
            {
                LocationNavBarType === "success" &&
                <LocationModalNavBar LocationNavBarType={LocationNavBarType} setLocatioNavBarType={setLocatioNavBarType} Order={Order} setOrders={setOrders} />
            }
            {
                NoAccess === "success" &&
                <AccessDeny NoAccess={NoAccess} setNoAccess={setNoAccess} />
            }
            {popupType != "" &&
                <PopupModal popupType={popupType} setPopupType={setPopupType} Message={message} />
            }
        </>
    )
}
export { SearchProducts };